import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_TABLE_SIZE } from '@core/constants/default-table-size.constant';
import { TableType } from '@core/enums/table-type.enum';

export type Settings = Record<TableType, number>

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private _tablesSettings$ = new BehaviorSubject<Settings>(DEFAULT_TABLE_SIZE);

  constructor(
    private readonly _httpClient: HttpClient,
  ) {
  }

  public getTableItemsPerPage(table: TableType): number {
    return this._tablesSettings$.getValue()[table] || DEFAULT_TABLE_SIZE[table];
  }

  public setTableItemsPerPage(table: TableType, size: number): void {
    const data: Settings = { ...this._tablesSettings$.getValue(), [table]: size };
    this._httpClient.patch('users/settings', data)
      .subscribe(() => this._tablesSettings$.next(data));
  }


  public loadSettings(): Observable<Settings> {
    return this._httpClient.get<Settings>('users/settings')
      .pipe(tap((data) => {
        if (!data) {
          return;
        }
        this._tablesSettings$.next(data);
      }));
  }

}
