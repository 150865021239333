export const MAX_PAGE_SIZE: number = 10000;
export const MAX_TG_MSG_SIZE: number = 4096;
export const MAX_TG_MSG_SIZE_WITH_ATTACHMENTS: number = 1024;
export const MAX_TG_MSG_ATTACHMENTS: number = 10;

export const USER_DATA_STORAGE_KEY: string = 'USER_DATA';

export const MAX_TG_ID: number = 999999999999999;

export const DEFAULT_AVATAR_PATH: string = './assets/icons/default-group-image.svg';
export const MAX_AVATAR_FILE_SIZE: number = 2097152; // 2MB
export const IMAGE_EXTENSIONS: string[] = [
  'img',
  'png',
  'svg',
  'pdf',
  'ai',
  'cdr',
  'jpeg',
  'gif',
  'raw',
  'tiff',
  'bmp',
  'psd',
  'heic',
  'jpg',
];
